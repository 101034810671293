<template>
  <Layout class="GameListWrapper">
    <BHeader>
      <el-form
        ref="form"
        :inline="true"
        :model="formData"
      >
        <el-form-item prop="search">
          <el-input
            v-model="formData.keyWord"
            clearable
            placeholder="游戏名称/ID"
            @change="formData.keyWord = formData.keyWord.trim()"
            @keyup.enter.native="handleSearch"
          />
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="formData.startMode"
            placeholder="启动模式"
            clearable
          >
            <el-option
              v-for="(item, index) in gameStartModeList"
              :key="index"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="formData.gameStatus"
            clearable
            @change="handleSearch"
          >
            <el-option
              v-for="item in startUsingListOne"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="handleSearch"
          >
            搜索
          </el-button>
        </el-form-item>
      </el-form>
      <template v-slot:right>
        <el-button
          v-permission="p$.game.addGame"
          type="primary"
          @click="handleAddGame"
        >
          添加游戏
        </el-button>
      </template>
    </BHeader>
    <el-table
      v-loading="gameListInfo.loading"
      :data="gameListInfo.list"
      stripe
      style="width: 100%"
    >
      <el-table-column type="expand">
        <template v-slot="{row}">
          <el-form
            label-position="left"
            class="table-expand"
          >
            <el-row>
              <el-col :span="12">
                <el-form-item label="是否支持租号">
                  <span>{{ row.brief.rentalSupport === 1 ? '支持' : '不支持' }}</span>
                </el-form-item>
                <el-form-item label="账号组">
                  <span>{{ row.accountGroup || '无' }}</span>
                </el-form-item>
                <el-form-item label="安装机房">
                  <span>{{ (row.brief.engineRoomInfo || []).join() || fullGameRoomList.join() }}</span>
                </el-form-item>
                <el-form-item label="启动路径">
                  <span>{{ row.brief.startPath || '/' }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="是否开启离线">
                  <span>{{ row.brief.offlineMode === 2 ? '开启' : '不开启' }}</span>
                </el-form-item>
                <el-form-item label="预设方案">
                  <span> {{ (keyboardPlanTypeMap[row.keyboardType] || {}).name || '未知' }}</span>
                </el-form-item>
                <el-form-item label="操作模式">
                  <span>{{ (operationModeMap[row.brief.operationMode] || {}).name || '未知' }}</span>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column
        prop="brief.sourceId"
        label="游戏ID"
        min-width="80"
      />
      <el-table-column
        prop="docs.en.gameName"
        label="游戏名称"
        min-width="100"
      >
        <template v-slot="{row}">
          {{ getValidField(row, 'gameName', 'docs') }}
        </template>
      </el-table-column>
      <el-table-column
        prop="brief.gameIdStr"
        label="Data ID"
        width="170"
      />
      <el-table-column
        prop="brief.platform"
        min-width="80"
        label="平台"
      >
        <template v-slot="{row}">
          {{ gamePlatformMap[row.brief.platform].name }}
        </template>
      </el-table-column>
      <el-table-column
        prop="docs.en.headerImage"
        min-width="120"
        label="封面图"
      >
        <template v-slot="{row}">
          <el-image
            v-if="getValidField(row, 'headerImage', 'docs')"
            class="game-cover"
            fit="contain"
            :src="getValidField(row, 'headerImage', 'docs')"
            lazy
          />
          <span v-else>Not Found</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="brief.startMode"
        min-width="100px"
        label="启动方式"
      >
        <template v-slot="{row}">
          {{ (gameStartModeMap[row.brief.startMode] || {}).name || '未知' }}
        </template>
      </el-table-column>
      <!--      <el-table-column-->
      <!--        prop="brief.heat"-->
      <!--        min-width="100px"-->
      <!--        label="热度"-->
      <!--      />-->
      <el-table-column
        fixed="right"
        width="120px"
        label="操作"
      >
        <template v-slot="{row}">
          <BTextButton
            v-permission="p$.game.editGame"
            title="编辑"
            icon="fa fa-pencil"
            @click="handleEditGame(row)"
          />
          <BTextButton
            :title="row.brief.gameStatus === 1 ? '禁用' : '启用'"
            :icon="`fa ${row.brief.gameStatus === 1 ? 'fa-check-circle' : 'fa-ban' }`"
            :loading="row.loading"
            @click="handleToggleRowState(row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :total="gameListInfo.total"
      :page.sync="formData.pageNum"
      :limit.sync="formData.pageSize"
      @pagination="queryGameList(formData)"
    />
  </Layout>
</template>

<script>
import { gameStartModeList, gamePlatformMap, gameStartModeMap, operationModeMap, keyboardPlanTypeMap, startUsingListOne } from '@/utils/selectOptions'
import { getEngineRoomInfo, toggleGameStatus } from '@/api/gameList'
import { mapState, mapActions } from 'vuex'
import { getValidField, skeletonFinish } from '@/utils'

export default {
  name: 'GameList',
  beforeRouteLeave (to, from, next) {
    if (document.querySelector('.main-view')) {
      sessionStorage.FeedbackSystemScrll = document.querySelector(
        '.main-view'
      ).scrollTop
    }

    next()
  },
  data () {
    return {
      startUsingListOne,
      gameStartModeList,
      gamePlatformMap,
      gameStartModeMap,
      operationModeMap,
      keyboardPlanTypeMap,
      formData: {
        keyWord: '',
        startMode: '',
        status: 1,
        pageNum: 1,
        pageSize: 20
      },
      fullGameRoomList: [],
      startUsingList: [] // 声明一个空数组
    }
  },
  computed: {
    ...mapState('gameList', ['gameListInfo'])
  },
  activated () {
    this.$nextTick(() => {
      if (document.querySelector('.main-view')) {
        document.querySelector('.main-view').scrollTo({
          top: sessionStorage.FeedbackSystemScrll,
          left: 0,
          behavior: 'auto'
        })
      }
    })
  },
  created () {
    this.queryGameList(this.formData)
    this.getEngineRoomInfo()
    this.skeletonFinish('gameListInfo.loading')
    this.startUsingList = startUsingListOne
    console.log(this.startUsingList, 'this.startUsingList')
  },
  methods: {
    ...mapActions('gameList', ['queryGameList']),
    getValidField,
    skeletonFinish,
    handleSearch () {
      this.formData.page = 1
      this.queryGameList(this.formData)
    },
    getEngineRoomInfo () {
      getEngineRoomInfo({})
        .then(res => {
          if (res.code === 200) {
            this.fullGameRoomList = (res.data.list || []).map(item => {
              return item.roomId
            })
          }
        })
    },
    handleAddGame () {
      sessionStorage.isEdit = '1'
      this.$router.push({
        name: 'GameCreate'
      })
    },
    handleEditGame (row) {
      sessionStorage.isEdit = '1'
      this.$router.push({
        name: 'GameEditIndex',
        query: {
          gameId: row.brief.gameIdStr
        }
      })
    },
    handleToggleRowState (row) {
      const currentStatus = row.brief.gameStatus
      const targetStatus = currentStatus === 1 ? 2 : 1
      const tips = targetStatus === 1 ? '此操作将启用游戏， 是否继续?' : '此操作将禁用游戏， 是否继续?'
      this.$confirm(tips, '提示', { type: 'warning ' })
        .then(() => {
          this.$set(row, 'loading', true)
          toggleGameStatus({
            gameIdStr: row.brief.gameIdStr,
            status: targetStatus
          }).then((res) => {
            if (res.code === 200) {
              this.$set(row.brief, 'gameStatus', targetStatus)
            }
          })
            .finally(() => {
              row.loading = false
            })
        })
    }
  }
}
</script>

<style lang="less">
  .GameListWrapper{
    .el-table__expanded-cell{
      background-color: #FAFCFD;
      .table-expand{
        label {
          width: 100px;
          color: #99a9bf;
        }
        .el-form-item{
          margin-bottom: 0;
        }
      }
    }
  }
</style>
<style scoped lang="less">
  .game-cover{
    width: 96px;
    height: 50px;
    border-radius: 4px;
  }
</style>
